import {
  Box,
  Button,
  Typography,
  TextField,
  InputAdornment,
  CssBaseline,
  Modal,
  Select,
  MenuItem,
} from "@mui/material";
import { useFormik } from "formik";
import React from "react";
import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";
import { useEffect, useState } from "react";
import NavbarComponent from "../component/navbar-component";
import Footer from "../component/footer-component";
import dayjs from "dayjs";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import "dayjs/locale/id";
import _ from "lodash";

// import * as Yup from "yup";
import Swal from "sweetalert2";
import axios from "axios";
import ListMenuItem from "../component/order-list-menu-item";
import ListCartItem from "../component/order-list-cart-item";
// import ListMenuItem from "../component/order-list-menu-item";

const boxVariant = {
  visible: { opacity: 1, scale: 1, transition: { duration: 0.5 } },
  hidden: { opacity: 0, scale: 1 },
};

const days = ["Minggu", "Senin", "Selasa", "Rabu", "Kamis", "Jum'at", "Sabtu"];
const months = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "Mei",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Okt",
  "Nov",
  "Des",
];

const menus = [
  {
    id: "001",
    name: "paket seojun reguler",
    desc: "2 Potong Ayam (Paha Atas Dan Paha Bawah) + Nasi Jumeokbap + Kimchi",
    price: 22500,
    custom: true,
    family: false,
  },
  {
    id: "002",
    name: "paket seojun jumbo",
    desc: "2 Potong Ayam (Dada Dan Sayap) + Nasi Jumeokbap + Kimchi",
    price: 25000,
    custom: true,
    family: false,
  },
  {
    id: "003",
    name: "Ala Carte Reguler",
    desc: "2 potong ayam (Paha Atas dan Paha Bawah)",
    price: 18000,
    custom: true,
    family: false,
  },
  {
    id: "004",
    name: "Ala Carte Jumbo",
    desc: "2 potong ayam (Dada dan Sayap)",
    price: 21000,
    custom: true,
    family: false,
  },
  {
    id: "005",
    name: "Paket Ekonomis Dada",
    desc: "Nasi Putih + 1 Pcs Ayam Dada Breast + Pilihan Sauce",
    price: 15000,
    custom: true,
    family: false,
  },
  {
    id: "006",
    name: "Paket Ekonomis Sayap",
    desc: "Nasi Putih + 1 Pcs Sayap + Pilihan Sauce",
    price: 12000,
    custom: true,
    family: false,
  },
  {
    id: "007",
    name: "Paket Ekonomis Paha Atas",
    desc: "Nasi Putih + 1 Pcs Paha Atas + Pilihan Sauce",
    price: 15000,
    custom: true,
    family: false,
  },
  {
    id: "008",
    name: "Paket Ekonomis Paha Bawah",
    desc: "Nasi Putih + 1 Pcs Paha Bawah + Pilihan Sauce",
    price: 11000,
    custom: true,
    family: false,
  },
  {
    id: "009",
    name: "Jumeokbap",
    desc: "Nasi Dengan Taburan Rumput Laut, Bawang, Minyak Wijen Dan Mayonaise",
    price: 6000,
    custom: false,
    family: false,
  },
  {
    id: "010",
    name: "Kimchi",
    desc: "Asinan khas korea berisi fermentasi sawi putih, wortel, bengkuang, bawang daun dan gochugaru",
    price: 5000,
    custom: false,
    family: false,
  },
  {
    id: "011",
    name: "Nasi Putih",
    desc: "Asinan khas korea berisi fermentasi sawi putih, wortel, bengkuang, bawang daun dan gochugaru",
    price: 4000,
    custom: false,
    family: false,
  },
  {
    id: "012",
    name: "Paket Hemat Family",
    desc: "1 Ekor Ayam (8 Potong) Korean Fried Chicken (Pilihan Sauce Maksimum 4 Rasa), 4 Jumeokbap, 4 Kimchi",
    price: 94000,
    custom: true,
    family: true,
  },
  {
    id: "013",
    name: "Paket Ala Carte Family",
    desc: "1 Ekor Ayam (8 Potong) Korean Fried Chicken (Pilihan Sauce Maksimum 4 Rasa)",
    price: 76000,
    custom: true,
    family: true,
  },
];

const sauces = [
  {
    id: 1,
    name: "Dak Ganjeong",
  },
  {
    id: 2,
    name: "Han Cheezy",
  },
  {
    id: 3,
    name: "Jun Doenjang",
  },
  {
    id: 4,
    name: "Song Beqyu",
  },
];

const branchs = [
  {
    id: 1,
    name: "jl. taman cibeunying",
  },
];

const BoxAnimate = ({ component }) => {
  // State definition
  const control = useAnimation();
  const [ref, inView] = useInView();

  useEffect(() => {
    if (inView) {
      control.start("visible");
    } else {
      control.start("hidden");
    }
  }, [control, inView]);

  return (
    <motion.div
      className="box"
      ref={ref}
      variants={boxVariant}
      initial="hidden"
      animate={control}
    >
      {component}
    </motion.div>
  );
};

const OrderPage = () => {
  // State definition
  const [pickValue, setPickValue] = useState(dayjs());
  const [listOrder, setListOrder] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [theOrder, setTheOrder] = useState({});
  const [selectSauce, setSelectSauce] = useState(1);
  const [isFam, setIsFam] = useState(false);
  const [famS1, setFamS1] = useState(1);
  const [famS2, setFamS2] = useState(1);
  const [famS3, setFamS3] = useState(1);
  const [famS4, setFamS4] = useState(1);

  const [branch, setBranch] = useState(1);
  const [totalPrice, setTotalPrice] = useState(0);

  // Normalize phone number input
  const normPhoneNumber = (event) => {
    let rawNumber = event.target.value;
    let numArr = Array.from(rawNumber);
    // console.log(rawNumber);

    if (numArr[0] === "0" && numArr.length > 1) {
      numArr.shift();
    }

    rawNumber = numArr.join("");
    return rawNumber;
  };

  // Handling order input
  const handleOrderInput = (order) => {
    setTheOrder(order);

    if (order.family) {
      setIsFam(true);
    } else {
      setIsFam(false);
    }

    if (order?.custom === true) {
      handleOpen();
    } else {
      addOrder(order);
    }
  };

  // Add order
  const addOrder = (order) => {
    let rawOrderArr = listOrder;

    // Create obj
    let tObj = {};

    if (!order.family) {
      if (order.custom === true) {
        let customId = order.id + sauces[selectSauce - 1].id;
        tObj = {
          ...order,
          sauce: sauces[selectSauce - 1].name,
          custom_id: customId,
          base_price: order.price,
        };
      } else {
        tObj = { ...order, custom_id: order.id, base_price: order.price };
      }
    } else {
      tObj = {
        ...order,
        sauce_1: sauces[famS1 - 1].name,
        sauce_2: sauces[famS2 - 1].name,
        sauce_3: sauces[famS3 - 1].name,
        sauce_4: sauces[famS4 - 1].name,
        custom_id: order.id,
        base_price: order.price,
      };
    }

    // console.log(tObj);
    // Check apakah menu sudah ada di dalam array rawOrderArr
    let kObj = {};
    if (_.some(rawOrderArr, { custom_id: tObj.custom_id })) {
      // if(order.custom){

      // }else{

      // }
      let gObj = _.find(rawOrderArr, { custom_id: tObj.custom_id });
      let qty = gObj.qty;
      let price = gObj.price;

      qty += 1;
      price += tObj.price;
      // console.log(gObj);

      _.remove(rawOrderArr, (item) => item.custom_id === tObj.custom_id);

      // Membuat object untuk keranjang pesanan
      kObj = {
        ...gObj,
        price: price,
        qty: qty,
      };

      rawOrderArr.push(kObj);
    } else {
      if (!order.family) {
        if (order.custom) {
          // Membuat object untuk keranjang pesanan
          kObj = {
            id: tObj.id,
            custom_id: tObj.custom_id,
            name: tObj.name,
            price: tObj.price,
            sauce: tObj?.sauce,
            qty: 1,
            base_price: tObj.base_price,
          };
        } else {
          // Membuat object untuk keranjang pesanan
          kObj = {
            id: tObj.id,
            custom_id: tObj.custom_id,
            name: tObj.name,
            price: tObj.price,
            qty: 1,
            base_price: tObj.base_price,
          };
        }
      } else {
        // Membuat object untuk keranjang pesanan
        kObj = {
          id: tObj.id,
          custom_id: tObj.custom_id,
          name: tObj.name,
          price: tObj.price,
          sauce_1: tObj.sauce_1,
          sauce_2: tObj.sauce_2,
          sauce_3: tObj.sauce_3,
          sauce_4: tObj.sauce_4,
          qty: 1,
          base_price: tObj.base_price,
        };
      }

      rawOrderArr.push(kObj);
    }

    setListOrder([...rawOrderArr]);
    handleClose();
  };

  // Set Formik variable
  useEffect(() => {
    // console.log("listOrder : ", listOrder);
    let pListOrder = listOrder;
    let strOrder = "";
    let strArr = [];

    pListOrder.forEach((order) => {
      let tStr = "";

      if (order.sauce) {
        tStr = `${order.name} - ${order.sauce} x ${order.qty}`;
      } else if (order.sauce_1) {
        // Family
        tStr = `${order.name} - ${order.sauce_1}, ${order.sauce_2}, ${order.sauce_3}, ${order.sauce_4} x ${order.qty}`;
      } else {
        tStr = `${order.name} x ${order.qty}`;
      }
      strArr.push(tStr);
    });

    strOrder = strArr.join("\n");
    // console.log(strOrder);

    // Itung total bayar
    let tPrice = 0;
    pListOrder.forEach((el) => {
      tPrice += el.price;
    });
    // console.log(tPrice);
    setTotalPrice(tPrice);

    formik.setFieldValue("order_item", strOrder);
  }, [listOrder]);

  // Tambah item dari cart
  let cartAddOrder = (order) => {
    // console.log(order);

    // Ambil data qty dari listOrder berdasarkan custom_id
    let pListOrder = listOrder;
    let nData = {};
    let pData = _.find(pListOrder, { custom_id: order.custom_id });
    let arrLoc = _.findIndex(pListOrder, { custom_id: order.custom_id });
    // console.log(arrLoc);

    // Ambil data qty dan tambah 1 item
    let pQty = pData.qty;
    let pBasePrice = pData.base_price;
    let pPrice = pData.price;
    pQty = pQty + 1;
    pPrice = pPrice + pBasePrice;

    nData = {
      ...pData,
      qty: pQty,
      price: pPrice,
    };

    // Remove data lama, dan ganti dengan data baru
    pListOrder[arrLoc] = { ...nData };
    setListOrder([...pListOrder]);
  };

  // Kurangi item dari cart
  let cartSubOrder = (order) => {
    // console.log(order);

    // Ambil data qty dari listOrder berdasarkan custom_id
    let pListOrder = listOrder;
    let nData = {};
    let pData = _.find(pListOrder, { custom_id: order.custom_id });
    let arrLoc = _.findIndex(pListOrder, { custom_id: order.custom_id });

    // Ambil data qty dan tambah 1 item
    let pQty = pData.qty;
    let pBasePrice = pData.base_price;
    let pPrice = pData.price;
    pQty = pQty - 1;
    pPrice = pPrice - pBasePrice;

    // Remove data lama, dan ganti dengan data baru
    if (pQty <= 0) {
      _.remove(pListOrder, { custom_id: order.custom_id });
    } else {
      nData = {
        ...pData,
        qty: pQty,
        price: pPrice,
      };
      pListOrder[arrLoc] = { ...nData };
    }

    setListOrder([...pListOrder]);
  };

  // Remove item dari cart
  let cartRemoveItem = (order) => {
    // console.log(order);

    // Remove item dari listOrder
    let pListOrder = listOrder;

    _.remove(pListOrder, (item) => item.custom_id === order.custom_id);

    setListOrder([...pListOrder]);
  };

  // Handling modal open
  const handleOpen = () => {
    setOpenModal(true);
  };

  // Handling close modal
  const handleClose = () => {
    setOpenModal(false);
  };

  const formik = useFormik({
    initialValues: {
      customer_name: "",
      contact: "",
      order_item: "",
      pickup_date: dayjs(),
      pickup_store: "",
    },
    onSubmit: (values) => {
      // alert(JSON.stringify(values, null, 2));
      console.log(values);

      // Normalisasi Tanggal
      let rDate = dayjs(values.pickup_date);
      let day = rDate.day();
      let date = rDate.date();
      let month = rDate.month();
      let year = rDate.year();
      let tHour = rDate.format("HH:mm");

      let strCom = `${days[day]}, ${date} ${months[month]} ${year}`;
      // let strTime = `${tHour}:${tMinutes}`;

      let waPayload = `Halo Seojun Fried Chicken, berikut pesanan saya :\nNama : ${values.customer_name}\nNo hp : +62${values.contact}\nMenu dan saus :\n${values.order_item}\n\nTotal bayar: ${totalPrice}\nWaktu pick up : ${strCom}\nJam : ${tHour}\nPickup Store : Jl. Taman Cibeunying`;

      let waPayload_encoded = encodeURIComponent(waPayload);
      let waLink = `https://wa.me/+6282219262613?text=${waPayload_encoded}`;
      // let waLink = `https://wa.me/+6281221002666?text=${waPayload_encoded}`;

      // console.log(waPayload);a
      // console.log(process.env.REACT_APP_BASE_URL_LOCAL);
      // window.location.replace(waLink);

      Swal.fire({
        title: "Pesanan Sudah Benar ?",
        showDenyButton: true,
        confirmButtonText: "Ya Pesan",
        denyButtonText: `Cek Lagi`,
      }).then((result) => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
          Swal.fire("Terimakasih", "Lanjut Konfirmasi ya", "success");
          axios({
            method: "post",
            // url: `http://localhost:5002/seojun/api/preorder`,
            url: `${
              process.env.REACT_APP_STATUS === "deployment"
                ? process.env.REACT_APP_BASE_URL
                : process.env.REACT_APP_BASE_URL_LOCAL
            }/seojun/api/preorder`,
            data: values,
            headers: {
              "Content-Type": "application/json",
              "Access-Control-Allow-Origin": "*",
            },
          })
            .then((data) => {
              if (data.data.success) {
                window.location.replace(waLink);
                formik.resetForm();
              }
            })
            .catch((err) => {
              Swal.fire({
                icon: "error",
                title: "Terjadi masalah saat pemesanan, Mohon Maaf.",
                showConfirmButton: false,
                timer: 1500,
              });
            });
        }
      });
    },
  });

  return (
    <div className="body">
      <CssBaseline />
      <BoxAnimate component={<NavbarComponent />} />
      <Box
        // minWidth="90vw"
        minHeight="80vh"
        backgroundColor="#ae2129"
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="flex-start"
      >
        <Box
          sx={{
            backgroundColor: "secondary.accent",
            borderRadius: 2,
            padding: 1,
            flexDirection: "column",
          }}
        >
          {/* Card Pesanan */}
          <Box
            sx={{
              width: { xs: "100%", sm: "23em", md: "23em", lg: "45em" },
              // maxWidth: "23em",
              flexDirection: "column",
              backgroundColor: "ivory",
              borderRadius: 2,
              p: 1.5,
            }}
          >
            <Typography
              fontSize="1.2em"
              fontWeight="bold"
              color="#ae2129"
              align="left"
              lineHeight="1.2"
            >
              Form Pesanan
            </Typography>
            <form autoComplete="off" onSubmit={formik.handleSubmit}>
              <Box
                sx={{
                  py: 2,
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <TextField
                  required
                  id="customer_name"
                  label="Nama Lengkap"
                  variant="outlined"
                  size="small"
                  color="primary"
                  value={formik.values.customer_name}
                  onChange={formik.handleChange}
                  InputLabelProps={{ style: { color: "#4F7170" } }}
                />
                <br />
                <TextField
                  required
                  variant="outlined"
                  id="contact"
                  label="Nomor Handphone"
                  size="small"
                  color="primary"
                  value={formik.values.contact}
                  onChange={(e) => {
                    formik.setFieldValue("contact", normPhoneNumber(e));
                  }}
                  InputLabelProps={{ style: { color: "#4F7170" } }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <font color="#4F7170">+62</font>
                      </InputAdornment>
                    ),
                  }}
                />
                <br />

                <Typography
                  sx={{
                    fontSize: "1em",
                    fontWeight: 500,
                    color: "#ae2129",
                    textTransform: "capitalize",
                  }}
                >
                  Daftar Menu
                </Typography>
                <Box
                  sx={{
                    maxHeight: "30em",
                    overflow: "scroll",
                  }}
                >
                  {menus.map((menu) => (
                    <ListMenuItem
                      key={menu.id}
                      item={menu}
                      order_input_func={handleOrderInput}
                    />
                  ))}
                </Box>

                {/* ============ List Pesanan ============ */}
                <br />
                <Typography
                  sx={{
                    fontSize: "1em",
                    fontWeight: 500,
                    color: "#ae2129",
                    textTransform: "capitalize",
                  }}
                >
                  daftar pesanan
                </Typography>
                {/* <>{listOrder[0]}</> */}

                {listOrder.length === 0 ? (
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: ".8em",
                      }}
                    >
                      -- belum ada pesanan --
                    </Typography>
                  </Box>
                ) : (
                  listOrder?.map((order) => (
                    <ListCartItem
                      key={order.custom_id}
                      item={order}
                      qty_add_func={cartAddOrder}
                      qty_sub_func={cartSubOrder}
                      delete_item={cartRemoveItem}
                    />
                  ))
                )}

                {listOrder.length !== 0 && (
                  <>
                    <Typography
                      sx={{
                        color: "red",
                        textTransform: "capitalize",
                        fontStyle: "italic",
                        fontSize: ".7em",
                      }}
                    >
                      pastikan pesanan sudah benar.
                    </Typography>
                    <Box
                      sx={{
                        width: "100%",
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "flex-end",
                      }}
                    >
                      <Typography
                        sx={{
                          textTransform: "capitalize",
                          fontSize: "1em",
                          fontWeight: "bold",
                        }}
                      >
                        total bayar : {totalPrice}
                      </Typography>
                    </Box>
                  </>
                )}

                <br />
                <LocalizationProvider
                  dateAdapter={AdapterDayjs}
                  adapterLocale="id"
                >
                  <DateTimePicker
                    id="pickup_date"
                    label="Waktu Pick Up"
                    // minutesStep={30}
                    timeSteps={{ minutes: 30 }}
                    value={pickValue}
                    onChange={(newValue) => {
                      // console.log(newValue);
                      setPickValue(newValue);
                      formik.setFieldValue(
                        "pickup_date",
                        `${dayjs(newValue).format("YYYY-MM-DDTHH:mm:ss.SSSZ")}`
                      );
                    }}
                  />
                </LocalizationProvider>

                <br />
                <Typography
                  sx={{
                    fontSize: ".8em",
                    fontWeight: 500,
                    textTransform: "capitalize",
                    marginBottom: ".2em",
                    color: "grey",
                  }}
                >
                  cabang pickup :
                </Typography>
                <Select
                  variant="outlined"
                  size="small"
                  color="primary"
                  value={branch}
                  onChange={(e) => setBranch(branchs[e.target.value])}
                >
                  <MenuItem value={1}>
                    <Typography
                      sx={{
                        fontSize: ".8em",
                        fontWeight: 500,
                        textTransform: "capitalize",
                        padding: 2,
                      }}
                    >
                      Jl. Taman CIbeunying
                    </Typography>
                  </MenuItem>
                </Select>
              </Box>
              <br />

              <Button type="submit" variant="contained" color="success">
                Pesan
              </Button>
              <Box />
            </form>
          </Box>
        </Box>
      </Box>

      {/* Modal */}
      <Modal
        open={openModal}
        onClose={handleClose}
        aria-labelledby="child-modal-title"
        aria-describedby="child-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            bgcolor: "background.paper",
            border: "2px solid #000",
            boxShadow: 24,
            pt: 2,
            px: 4,
            pb: 3,

            display: "flex",
            flexDirection: "column",
          }}
        >
          {!isFam ? (
            <>
              <Typography
                sx={{
                  fontSize: "1em",
                  fontWeight: 500,
                  color: "#ae2129",
                  textTransform: "capitalize",
                  marginBottom: "1em",
                }}
              >
                pilihan saus
              </Typography>
              <Select
                variant="standard"
                size="small"
                color="primary"
                value={selectSauce}
                onChange={(event) => {
                  setSelectSauce(event.target.value);
                }}
              >
                <MenuItem value={1}>
                  <Typography
                    sx={{
                      fontSize: ".8em",
                      fontWeight: 500,
                      textTransform: "capitalize",
                      padding: 2,
                    }}
                  >
                    dak ganjeong
                  </Typography>
                </MenuItem>
                ;
                <MenuItem value={2}>
                  <Typography
                    sx={{
                      fontSize: ".8em",
                      fontWeight: 500,
                      textTransform: "capitalize",
                      padding: 2,
                    }}
                  >
                    han cheezy
                  </Typography>
                </MenuItem>
                ;
                <MenuItem value={3}>
                  <Typography
                    sx={{
                      fontSize: ".8em",
                      fontWeight: 500,
                      textTransform: "capitalize",
                      padding: 2,
                    }}
                  >
                    jun doenjang
                  </Typography>
                </MenuItem>
                ;
                <MenuItem value={4}>
                  <Typography
                    sx={{
                      fontSize: ".8em",
                      fontWeight: 500,
                      textTransform: "capitalize",
                      padding: 2,
                    }}
                  >
                    song beqyu
                  </Typography>
                </MenuItem>
                ;
              </Select>
            </>
          ) : (
            <>
              <Typography
                sx={{
                  fontSize: "1em",
                  fontWeight: 500,
                  color: "#ae2129",
                  textTransform: "capitalize",
                  marginBottom: "1em",
                }}
              >
                pilihan saus family
              </Typography>

              {/* Saus 1 */}
              <Typography
                sx={{
                  fontSize: ".6em",
                  fontWeight: 500,
                  color: "#ae2129",
                  textTransform: "capitalize",
                  marginBottom: ".5em",
                  marginTop: "1em",
                }}
              >
                pilihan saus 1
              </Typography>
              <Select
                variant="standard"
                size="small"
                color="primary"
                value={famS1}
                onChange={(event) => {
                  setFamS1(event.target.value);
                }}
              >
                <MenuItem value={1}>
                  <Typography
                    sx={{
                      fontSize: ".8em",
                      fontWeight: 500,
                      textTransform: "capitalize",
                      padding: 2,
                    }}
                  >
                    dak ganjeong
                  </Typography>
                </MenuItem>
                ;
                <MenuItem value={2}>
                  <Typography
                    sx={{
                      fontSize: ".8em",
                      fontWeight: 500,
                      textTransform: "capitalize",
                      padding: 2,
                    }}
                  >
                    han cheezy
                  </Typography>
                </MenuItem>
                ;
                <MenuItem value={3}>
                  <Typography
                    sx={{
                      fontSize: ".8em",
                      fontWeight: 500,
                      textTransform: "capitalize",
                      padding: 2,
                    }}
                  >
                    jun doenjang
                  </Typography>
                </MenuItem>
                ;
                <MenuItem value={4}>
                  <Typography
                    sx={{
                      fontSize: ".8em",
                      fontWeight: 500,
                      textTransform: "capitalize",
                      padding: 2,
                    }}
                  >
                    song beqyu
                  </Typography>
                </MenuItem>
                ;
              </Select>

              {/* Saus 2 */}
              <Typography
                sx={{
                  fontSize: ".6em",
                  fontWeight: 500,
                  color: "#ae2129",
                  textTransform: "capitalize",
                  marginBottom: ".5em",
                  marginTop: "1em",
                }}
              >
                pilihan saus 2
              </Typography>
              <Select
                variant="standard"
                size="small"
                color="primary"
                value={famS2}
                onChange={(event) => {
                  setFamS2(event.target.value);
                }}
              >
                <MenuItem value={1}>
                  <Typography
                    sx={{
                      fontSize: ".8em",
                      fontWeight: 500,
                      textTransform: "capitalize",
                      padding: 2,
                    }}
                  >
                    dak ganjeong
                  </Typography>
                </MenuItem>
                ;
                <MenuItem value={2}>
                  <Typography
                    sx={{
                      fontSize: ".8em",
                      fontWeight: 500,
                      textTransform: "capitalize",
                      padding: 2,
                    }}
                  >
                    han cheezy
                  </Typography>
                </MenuItem>
                ;
                <MenuItem value={3}>
                  <Typography
                    sx={{
                      fontSize: ".8em",
                      fontWeight: 500,
                      textTransform: "capitalize",
                      padding: 2,
                    }}
                  >
                    jun doenjang
                  </Typography>
                </MenuItem>
                ;
                <MenuItem value={4}>
                  <Typography
                    sx={{
                      fontSize: ".8em",
                      fontWeight: 500,
                      textTransform: "capitalize",
                      padding: 2,
                    }}
                  >
                    song beqyu
                  </Typography>
                </MenuItem>
                ;
              </Select>

              {/* Saus 3 */}
              <Typography
                sx={{
                  fontSize: ".6em",
                  fontWeight: 500,
                  color: "#ae2129",
                  textTransform: "capitalize",
                  marginBottom: ".5em",
                  marginTop: "1em",
                }}
              >
                pilihan saus 3
              </Typography>
              <Select
                variant="standard"
                size="small"
                color="primary"
                value={famS3}
                onChange={(event) => {
                  setFamS3(event.target.value);
                }}
              >
                <MenuItem value={1}>
                  <Typography
                    sx={{
                      fontSize: ".8em",
                      fontWeight: 500,
                      textTransform: "capitalize",
                      padding: 2,
                    }}
                  >
                    dak ganjeong
                  </Typography>
                </MenuItem>
                ;
                <MenuItem value={2}>
                  <Typography
                    sx={{
                      fontSize: ".8em",
                      fontWeight: 500,
                      textTransform: "capitalize",
                      padding: 2,
                    }}
                  >
                    han cheezy
                  </Typography>
                </MenuItem>
                ;
                <MenuItem value={3}>
                  <Typography
                    sx={{
                      fontSize: ".8em",
                      fontWeight: 500,
                      textTransform: "capitalize",
                      padding: 2,
                    }}
                  >
                    jun doenjang
                  </Typography>
                </MenuItem>
                ;
                <MenuItem value={4}>
                  <Typography
                    sx={{
                      fontSize: ".8em",
                      fontWeight: 500,
                      textTransform: "capitalize",
                      padding: 2,
                    }}
                  >
                    song beqyu
                  </Typography>
                </MenuItem>
                ;
              </Select>

              {/* Saus 4 */}
              <Typography
                sx={{
                  fontSize: ".6em",
                  fontWeight: 500,
                  color: "#ae2129",
                  textTransform: "capitalize",
                  marginBottom: ".5em",
                  marginTop: "1em",
                }}
              >
                pilihan saus 4
              </Typography>
              <Select
                variant="standard"
                size="small"
                color="primary"
                value={famS4}
                onChange={(event) => {
                  setFamS4(event.target.value);
                }}
              >
                <MenuItem value={1}>
                  <Typography
                    sx={{
                      fontSize: ".8em",
                      fontWeight: 500,
                      textTransform: "capitalize",
                      padding: 2,
                    }}
                  >
                    dak ganjeong
                  </Typography>
                </MenuItem>
                ;
                <MenuItem value={2}>
                  <Typography
                    sx={{
                      fontSize: ".8em",
                      fontWeight: 500,
                      textTransform: "capitalize",
                      padding: 2,
                    }}
                  >
                    han cheezy
                  </Typography>
                </MenuItem>
                ;
                <MenuItem value={3}>
                  <Typography
                    sx={{
                      fontSize: ".8em",
                      fontWeight: 500,
                      textTransform: "capitalize",
                      padding: 2,
                    }}
                  >
                    jun doenjang
                  </Typography>
                </MenuItem>
                ;
                <MenuItem value={4}>
                  <Typography
                    sx={{
                      fontSize: ".8em",
                      fontWeight: 500,
                      textTransform: "capitalize",
                      padding: 2,
                    }}
                  >
                    song beqyu
                  </Typography>
                </MenuItem>
                ;
              </Select>
            </>
          )}

          <br />
          <Button variant="outlined" onClick={() => addOrder(theOrder)}>
            Tambahkan
          </Button>
        </Box>
      </Modal>
      <Footer />
    </div>
  );
};

export default OrderPage;
