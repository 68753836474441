import { Box, Typography } from "@mui/material";
import PaketReg from "../assets/menu/paket_hemat_reg.png";
import PaketJum from "../assets/menu/paket_hemat_jumbo.png";
import AlaCarteReg from "../assets/menu/ala_carte_reg.png";
import AlaCarteJumbo from "../assets/menu/ala_carte_jumbo.png";

const MenuElementNew = () => {
  return (
    <Box width="100%" display="flex" flexDirection="column">
      {/* Paket */}
      <Box display="flex" flexDirection="row">
        <Box
          flex={1}
          minHeight="10vh"
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
        >
          <img src={PaketReg} width="60%" alt="paket_hemat_reguler" />
        </Box>

        <Box
          flex={1}
          minHeight="10vh"
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
        >
          <img src={PaketJum} width="80%" alt="paket_hemat_reguler" />
        </Box>
      </Box>
      <Box display="flex" flexDirection="row" gap={{ xs: 1, sm: 1 }}>
        <Box
          flex={1}
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
        >
          <Typography
            fontWeight="bold"
            fontSize={{ xs: "1rem", sm: "1rem", md: "1.5rem" }}
            color="ivory"
            textTransform="capitalize"
            textAlign="center"
          >
            Paket Hemat Seo Jun Reguler
          </Typography>

          <Typography
            fontSize="0.8rem"
            fontWeight={{ xs: "500", sm: "500", md: "700" }}
            color="ivory"
            textTransform="capitalize"
            marginTop={{ xs: ".5rem" }}
          >
            2 potong ayam goreng bumbu ( paha atas dan paha bawah ), nasi
            jumeokbap dan kimchi
          </Typography>
        </Box>

        <Box
          flex={1}
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
        >
          <Typography
            fontWeight="bold"
            fontSize={{ xs: "1rem", sm: "1rem", md: "1.5rem" }}
            color="ivory"
            textAlign="center"
          >
            Paket Hemat Seo Jun Jumbo
          </Typography>
          <Typography
            fontWeight={{ xs: "500", sm: "500", md: "700" }}
            fontSize="0.8rem"
            color="ivory"
            textTransform="capitalize"
            marginTop={{ xs: ".5rem" }}
          >
            2 potong ayam goreng bumbu ( Dada dan Sayap ), nasi jumeokbap dan
            kimchi
          </Typography>
        </Box>
      </Box>

      <br />
      {/* A La Carte */}
      <Box display="flex" flexDirection="row">
        <Box
          flex={1}
          minHeight="10vh"
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
        >
          <img src={AlaCarteReg} width="50%" alt="paket_hemat_reguler" />
        </Box>

        <Box
          flex={1}
          minHeight="10vh"
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
        >
          <img src={AlaCarteJumbo} width="60%" alt="paket_hemat_reguler" />
        </Box>
      </Box>
      <Box display="flex" flexDirection="row" alignItems="flex-start">
        <Box
          flex={1}
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
        >
          <Typography
            fontWeight="bold"
            fontSize={{ xs: "1rem", sm: "1rem", md: "1.5rem" }}
            color="ivory"
            textTransform="capitalize"
          >
            A La Carte Reguler
          </Typography>

          <Typography
            fontWeight={{ xs: "500", sm: "500", md: "700" }}
            fontSize="0.8rem"
            color="ivory"
            textTransform="capitalize"
            marginTop={{ xs: ".5rem" }}
          >
            2 potong ayam goreng bumbu ( paha atas dan paha bawah )
          </Typography>
        </Box>

        <Box
          flex={1}
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
        >
          <Typography
            fontWeight="bold"
            fontSize={{ xs: "1rem", sm: "1rem", md: "1.5rem" }}
            color="ivory"
          >
            A La Carte Jumbo
          </Typography>
          <Typography
            fontWeight={{ xs: "500", sm: "500", md: "700" }}
            fontSize="0.8rem"
            color="ivory"
            textTransform="capitalize"
            marginTop={{ xs: ".5rem" }}
          >
            2 potong ayam goreng bumbu ( Dada dan Sayap )
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default MenuElementNew;
