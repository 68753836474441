import {
  Box,
  CssBaseline,
  Divider,
  Typography,
  useMediaQuery,
} from "@mui/material";
import Carousel from "./carousel";
import LogoGofood from "../assets/logo_gofood_trans.png";
import ReactGA from "react-ga";

const CarouselContainer = () => {
  // Screen size definition
  const mobile_size = useMediaQuery("(max-width:800px)");
  const desktop_size = useMediaQuery("(min-width:800px)");

  const OPTIONS = {};
  const SLIDE_COUNT = 3;
  const SLIDES = Array.from(Array(SLIDE_COUNT).keys());

  const desc =
    "Telusuri berbagai varian rasa dari ayam goreng korea khas Seo Jun, yang siap jadi teman makanmu setiap hari";

  return (
    <>
      <CssBaseline />
      <Box
        width="100vw"
        padding="1em"
        display="flex"
        flexDirection="column"
        alignItems="center"
        backgroundColor="ivory"
      >
        {desktop_size && <Carousel slides={SLIDES} options={OPTIONS} />}

        {mobile_size && (
          <Box
            width="95%"
            height="100%"
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            overflow="hidden"
          >
            <Typography
              fontSize="1.2em"
              fontWeight={700}
              textTransform="capitalize"
              textAlign="center"
              lineHeight={2}
              color="#37250f"
            >
              ayam goreng korea berbagai rasa
            </Typography>

            <Typography
              width="80%"
              fontSize=".8em"
              fontWeight={400}
              textAlign="center"
              lineHeight={2}
              color="#37250f"
            >
              {desc}
            </Typography>

            <br />
            <Divider width="80%" color="#ae3d21" />
            <br />
            <Typography
              fontSize=".8em"
              fontWeight={400}
              textAlign="center"
              color="#37250f"
            >
              Pre Order Di Sini :
            </Typography>
            <Box
              width="100%"
              height="100%"
              display="flex"
              flexDirection="row"
              alignItems="center"
              justifyContent="center"
            >
              <a href="/pre-order">
                <Typography
                  sx={{
                    textDecoration: "none",
                  }}
                >
                  seojunfriedchicken.com/pre-order
                </Typography>
              </a>
            </Box>
            <br />
            <Typography
              fontSize=".8em"
              fontWeight={400}
              textAlign="center"
              color="#37250f"
            >
              Atau pesan di :
            </Typography>

            <Box
              width="100%"
              height="100%"
              display="flex"
              flexDirection="row"
              alignItems="center"
              justifyContent="center"
            >
              <button
                className="btn-mobile"
                onClick={(event) => {
                  event.preventDefault();
                  ReactGA.event({
                    category: "CTA",
                    action: "gofoodClick",
                    label: "gofood_banner_mobile",
                  });
                  window.open("https://gofood.link/a/HKJ97Fw");
                }}
              >
                <img src={LogoGofood} alt="logo" width="80em" />
              </button>
            </Box>
          </Box>
        )}
      </Box>
    </>
  );
};

export default CarouselContainer;
