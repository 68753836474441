import logo from "../assets/logo.png";

const NavbarComponent = () => {
  return (
    <div className="navbar-container">
      <img src={logo} className="App-logo" alt="logo-seojun" />
    </div>
  );
};

export default NavbarComponent;
