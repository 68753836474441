import Autoplay from "embla-carousel-autoplay";
import useEmblaCarousel from "embla-carousel-react";
import sausImageIndex from "./menu-saus-images";
import { Box, Typography } from "@mui/material";

const CarouselSauce = (props) => {
  const { slides, options, sauce_list } = props;
  const [emblaRef] = useEmblaCarousel(options, [Autoplay()]);

  return (
    <div className="embla-mobile">
      <div className="embla__viewport-mobile" ref={emblaRef}>
        <div className="embla__container-mobile">
          {slides.map((index) => (
            <div className="embla__slide-mobile" key={index}>
              <div className="dot-background-carousel">
                <Box
                  width="100%"
                  display="flex"
                  flexDirection="row"
                  alignItems="center"
                  justifyContent="center"
                  gap={1.2}
                >
                  <Box flex={1}>
                    <img
                      className="embla__slide__img__mobile"
                      src={sausImageIndex(index)}
                      alt="sauce"
                    />
                  </Box>
                  <Box flex={1.2}>
                    <Typography
                      fontSize="1.2em"
                      fontWeight={700}
                      textTransform="capitalize"
                      color="#37250f"
                    >
                      {sauce_list[index].saus_name}
                    </Typography>
                    <Typography
                      width="60%"
                      fontSize=".7em"
                      fontWeight={400}
                      textTransform="capitalize"
                      color="#37250f"
                      overflow="clip"
                      marginTop={1}
                    >
                      {sauce_list[index].description}
                    </Typography>
                  </Box>
                </Box>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default CarouselSauce;
