// import logo from "../assets/logo.png";
import "../App.css";
import "../styles/new-styles.css";
import { CssBaseline } from "@mui/material";
import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";
import { useEffect, useState } from "react";
// import TaglineComponent from "../component/tagline-component";
import NavbarComponent from "../component/navbar-component";
import Footer from "../component/footer-component";
import CarouselContainer from "../component/carousel-container";
import MenuContainer from "../component/menu-container-component";
import LocationContainer from "../component/location-container-component";
import Banner from "../component/banner-component";
import MenuSauce from "../component/menu-sauce-component";

const boxVariant = {
  visible: { opacity: 1, scale: 1, transition: { duration: 0.5 } },
  hidden: { opacity: 0, scale: 1 },
};

const BoxAnimate = ({ component }) => {
  const control = useAnimation();
  const [ref, inView] = useInView();

  useEffect(() => {
    if (inView) {
      control.start("visible");
    } else {
      control.start("hidden");
    }
  }, [control, inView]);

  return (
    <motion.div
      className="box"
      ref={ref}
      variants={boxVariant}
      initial="hidden"
      animate={control}
    >
      {component}
    </motion.div>
  );
};

const Homepage = () => {
  const [renderNext, setRenderNext] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setRenderNext(true);
    }, 1000);
  }, []);

  return (
    <div className="body">
      <CssBaseline />
      <BoxAnimate component={<NavbarComponent />} />
      <div className="landing-base-container">
        {renderNext && <BoxAnimate component={<Banner />} />}
        {renderNext && <BoxAnimate component={<CarouselContainer />} />}
        <br />
        {renderNext && <BoxAnimate component={<MenuContainer />} />}
        <br />
        {renderNext && <BoxAnimate component={<MenuSauce />} />}

        {renderNext && <BoxAnimate component={<LocationContainer />} />}
      </div>

      <Footer />
    </div>
  );
};

export default Homepage;
