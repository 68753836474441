import { Box, Divider, Typography, useMediaQuery } from "@mui/material";
import { motion } from "framer-motion";
import { useEffect, useState } from "react";
import PlaceIcon from "@mui/icons-material/Place";
// import LogoGofood from "../assets/logo_gofood_trans.png";
import PaheJumbo from "../assets/menu/paket_hemat_jumbo.png";
import BannerImage from "../assets/banner_img.jpeg";
import ReactGA from "react-ga";

const Banner = () => {
  // Screen size definition
  const mobile_size = useMediaQuery("(max-width:800px)");
  const desktop_size = useMediaQuery("(min-width:800px)");

  // State definition
  const [renderImg, setRenderImg] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setRenderImg(true);
    }, 1300);
  }, []);

  return (
    <>
      {/* Mobile */}
      {mobile_size && (
        <div className="banner-background-mobile">
          <img
            src={BannerImage}
            alt="banner"
            className="banner-background-image"
          />
          <Box
            width="100%"
            height="100%"
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            overflow="hidden"
            backgroundColor="#e1292964"
          >
            <Box
              display="flex"
              flexDirection="column"
              alignItems="center"
              justifyContent="flex-end"
              zIndex={100}
            >
              <Typography
                fontSize="2em"
                fontWeight="bold"
                textTransform="capitalize"
                textAlign="left"
                color="ivory"
              >
                Seo Jun Fried Chicken
              </Typography>
              <Typography
                fontSize=".9em"
                fontWeight="bold"
                textTransform="capitalize"
                textAlign="left"
                color="ivory"
              >
                Your Daily Happiness
              </Typography>
            </Box>
          </Box>
        </div>
      )}

      {/* Desktop */}
      {desktop_size && (
        <div className="banner-background">
          <Box
            width="70vw"
            height="20em"
            display="flex"
            flexDirection="row"
            alignItems="center"
            justifyContent="center"
            overflow="hidden"
          >
            <Box
              flex={1.5}
              display="flex"
              flexDirection="column"
              alignItems="flex-start"
              justifyContent="flex-start"
              paddingLeft={2}
              // position="absolute"
              zIndex={100}
            >
              <Typography
                fontSize="1.2em"
                fontWeight={700}
                textTransform="capitalize"
                textAlign="left"
                lineHeight={2}
                color="#ae2129"
              >
                ayam goreng bumbu bergaya korea dengan berbagai rasa.
                <br />
                pilih rasa favoritmu, untuk menemani hari-harimu.
              </Typography>

              <br />

              <Typography
                fontSize="1em"
                fontWeight={400}
                textTransform="capitalize"
                fontStyle="italic"
                textAlign="left"
                lineHeight={1.8}
                color="#ae2129"
              >
                seo jun fried chicken
                <br />
                your daily happiness
              </Typography>

              <br />
              <Box
                display="flex"
                flexDirection="row"
                justifyContent="center"
                alignItems="center"
              >
                <div
                  className="btn btn-one"
                  onClick={(event) => {
                    event.preventDefault();
                    ReactGA.event({
                      category: "CTA",
                      action: "locationClick",
                      label: "location_banner",
                    });
                    window.open("https://goo.gl/maps/8Xh7yuJE17W848zq8");
                  }}
                >
                  <PlaceIcon />
                  <span>Kunjungi kami</span>
                </div>

                <Divider
                  orientation="vertical"
                  flexItem
                  color="#ae3d21"
                  marginleft="4px"
                  marginright="4px"
                />
                <div
                  className="btn btn-one"
                  onClick={(event) => {
                    event.preventDefault();
                    ReactGA.event({
                      category: "CTA",
                      action: "gofoodClick",
                      label: "gofood_banner",
                    });
                    window.open("/pre-order");
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: "1em",
                      fontWeight: "bold",
                    }}
                  >
                    PRE-ORDER
                  </Typography>
                </div>
              </Box>
            </Box>

            <Box
              flex={1}
              // position="absolute"
              // right="-25em"
              // bottom="9em"
            >
              {renderImg && (
                <motion.div
                  initial={{ opacity: 0, scale: 0.5 }}
                  animate={{ opacity: 1, scale: 1 }}
                  transition={{
                    duration: 0.3,
                    ease: [0, 0.71, 0.2, 1.01],
                    scale: {
                      type: "spring",
                      damping: 6,
                      stiffness: 40,
                      restDelta: 0.001,
                    },
                  }}
                >
                  <img src={PaheJumbo} alt="pahe jumbo" width="100%" />
                </motion.div>
              )}
            </Box>
          </Box>
        </div>
      )}
    </>
  );
};

export default Banner;
