import { Box, Typography } from "@mui/material";
import sausImageIndex from "./menu-saus-images";

const SausElement = ({ sausName, sausDescription, sausPic }) => {
  return (
    <Box
      flex={1}
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
    >
      <Box width="12em" height="12em" overflow="hidden">
        <img src={sausImageIndex(sausPic)} alt="saus seo jun" width="90%" />
      </Box>

      <Typography
        fontWeight="bold"
        fontSize="1rem"
        color="#37250f"
        textTransform="capitalize"
      >
        {sausName}
      </Typography>
      <Typography
        fontWeight="400"
        fontSize=".9rem"
        color="#37250f"
        textTransform="capitalize"
        textAlign="center"
      >
        {sausDescription}
      </Typography>
    </Box>
  );
};

export default SausElement;
