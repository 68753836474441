import {
  Box,
  CssBaseline,
  Divider,
  IconButton,
  useMediaQuery,
} from "@mui/material";
import InstagramIcon from "@mui/icons-material/Instagram";
import logo from "../assets/logo.png";
import Gofood_logo from "../assets/logo_gofood_trans.png";

const Footer = () => {
  const mobile_size = useMediaQuery("(max-width:600px)");
  const desktop_size = useMediaQuery("(min-width:600px)");

  return (
    <>
      <CssBaseline />

      {/* Mobile */}
      {mobile_size && (
        <Box
          width="100%"
          backgroundColor="ivory"
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          paddingTop="1em"
        >
          {/* Detail Logo */}
          <Box
            width="100%"
            display="flex"
            flexDirection="column"
            // backgroundColor="#ae2129"
            alignItems="center"
            justifyContent="center"
            marginBottom="1em"
            paddingTop=".2em"
            paddingBottom=".2em"
            borderRadius="2em"
          >
            <img src={logo} className="footer-logo" alt="logo-seojun" />
          </Box>

          {/* Title and Subtitle */}
          <div className="footer-title-mobile">Seojun Fried Chicken</div>
          <div className="footer-subtitle-mobile">Your daily happiness</div>

          <Divider width="90%" height="4px" color="grey" margintop="1em" />

          {/* Navigation */}
          <Box
            width="80%"
            display="flex"
            flexDirection="column"
            justifyContent="flex-start"
            alignItems="center"
            margintop="1em"
          >
            <div className="footer-title-mobile">Navigation</div>
            <a className="footer-link-mobile" href="#section-menu">
              Menu
            </a>
            {/* <a className="footer-link-mobile" href="/#">
              Location
            </a> */}
          </Box>

          <br />

          <div className="footer-title-mobile">Follow</div>
          {/* List icon */}
          <div className="footer-social-btn-list">
            <IconButton
              onClick={() => {
                window.open(
                  "https://instagram.com/seojunfriedchicken?igshid=MzRlODBiNWFlZA=="
                );
              }}
            >
              <InstagramIcon sx={{ width: "1em", height: "1em" }} />
            </IconButton>
          </div>

          <br />
          <div className="footer-title-mobile">Available at</div>
          {/* List icon */}
          <div className="footer-social-btn-list-mobile">
            <div
              className="footer-icon-element-mobile"
              onClick={() => {
                window.open("https://gofood.link/a/HKJ97Fw");
              }}
            >
              <img src={Gofood_logo} alt="gofood" width="30%" />
            </div>
          </div>

          <br />
          <Divider width="90%" height="4px" color="grey" margintop="1em" />

          {/* Location */}
          <Box
            width="100%"
            minHeight="2em"
            padding="1em"
            backgroundColor="ivory"
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
          >
            <div className="footer-subtitle-mobile">
              Jl. Taman Cibeunying Utara, Bandung Wetan, 40114
            </div>
          </Box>

          {/* Copyright */}
          <div className="footer-bottom-text">© 2023 seojunfriedchicken</div>
        </Box>
      )}

      {/* Desktop */}
      {desktop_size && (
        <Box
          width="100%"
          minHeight="2em"
          padding="1em"
          display="flex"
          flexDirection="column"
          alignItems="center"
          backgroundColor="ivory"
        >
          <Box
            width="80vw"
            display="flex"
            flexDirection="row"
            alignItems="flex-start"
            justifyContent="center"
            sx={{ paddingY: "1em" }}
            marginBottom="1em"
          >
            <Box flex="1">
              <div className="footer-title">Seojun Fried Chicken</div>
              <div className="footer-subtitle">Your daily happiness</div>

              <div className="footer-bottom-text">
                © 2023 seojunfriedchicken
              </div>
            </Box>
            <Box flex="1" display="flex" flexDirection="column">
              <div className="footer-title">Navigation</div>
              <a className="footer-link" href="#section-menu">
                Menu
              </a>
              {/* <a className="footer-link" href="#section-location">
                Location
              </a> */}
            </Box>

            <Box flex="1">
              <div className="footer-title">Follow Us</div>
              <div className="footer-social-btn-list">
                <IconButton
                  onClick={() => {
                    window.open(
                      "https://instagram.com/seojunfriedchicken?igshid=MzRlODBiNWFlZA=="
                    );
                  }}
                >
                  <InstagramIcon sx={{ width: "1em", height: "1em" }} />
                </IconButton>
              </div>
            </Box>

            <Box flex="1">
              <div className="footer-title">Location</div>
              <div className="footer-subtitle">
                Jl. Taman Cibeunying Utara, Bandung Wetan, 40114
              </div>
            </Box>
          </Box>
        </Box>
      )}
    </>
  );
};
export default Footer;
