import image1 from "../assets/saus/kim_curry.png";
import image2 from "../assets/saus/han_cheezy.png";
import image3 from "../assets/saus/song_beqyu.png";
import image4 from "../assets/saus/dak_ganjeong.png";
import image5 from "../assets/saus/park_galbi.png";
import image6 from "../assets/saus/jun_doenjang.png";

export const images = [image1, image2, image3, image4, image5, image6];

const sausImageIndex = (index) => images[index];

export default sausImageIndex;
