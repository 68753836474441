import { Box, CssBaseline, Typography, useMediaQuery } from "@mui/material";
import SausElement from "./menu-saus-element";
import CarouselSauce from "./carousel-sauce";

const MenuSauce = () => {
  // Screen size definition
  const mobile_size = useMediaQuery("(max-width:800px)");
  const desktop_size = useMediaQuery("(min-width:800px)");

  // Carousel config
  const OPTIONS = { loop: true };
  const SLIDE_COUNT = 6;
  const SLIDES = Array.from(Array(SLIDE_COUNT).keys());

  // Saus List
  const sausList = [
    {
      id: 0,
      saus_name: "kim curry",
      description: "Saus kari rempah yang bercitarasa kuat dan creamy",
    },
    {
      id: 1,
      saus_name: "han cheezy",
      description: "Saus keju original SeoJun, yang lembut, gurih dan creamy",
    },
    {
      id: 2,
      saus_name: "song beqyu",
      description:
        "Taburan bumbu kering yang gurih, dengan rasa manis dan asin ",
    },
    {
      id: 3,
      saus_name: "dak ganjeong",
      description: "Saus gochujang khas korea dengan rasa pedas manis",
    },
    {
      id: 4,
      saus_name: "park galbi",
      description: "Saus korea yang asin, manis dan smokey",
    },
    {
      id: 5,
      saus_name: "jun doenjang",
      description:
        "Saus tauco korea bercitarasa manis dan gurih, dengan aroma yang unik",
    },
  ];

  return (
    <>
      <CssBaseline />
      <Box
        width="100vw"
        minHeight="12em"
        backgroundColor="#FBFBFA"
        overflow="hidden"
        display="flex"
        paddingTop="1em"
        flexDirection="column"
        alignItems="center"
        justifyContent="flex-start"
      >
        <Box
          width="80vw"
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
        >
          <Typography
            color="#37250f"
            fontWeight="bold"
            fontSize={{ xs: "1rem", sm: "1rem", md: "1.5rem" }}
          >
            Telusuri Bumbu Ayam Goreng Seo Jun
          </Typography>
          <br />

          <Box
            display="flex"
            flexDirection="row"
            alignItems="flex-start"
            justifyContent="center"
            gap={6}
          >
            {/* Mobile */}
            {mobile_size && (
              <CarouselSauce
                slides={SLIDES}
                option={OPTIONS}
                sauce_list={sausList}
              />
            )}

            {desktop_size && (
              <>
                {sausList.map((ele) => (
                  <SausElement
                    key={ele.id}
                    sausName={ele.saus_name}
                    sausDescription={ele.description}
                    sausPic={ele.id}
                  />
                ))}
              </>
            )}
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default MenuSauce;
