import { Box, Button, Divider, Paper, Typography } from "@mui/material";

const ListMenuItem = ({ item, order_input_func }) => {
  return (
    <div>
      <Paper
        sx={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          padding: 1.2,
          marginBottom: ".6em",
        }}
      >
        <Box
          sx={{
            width: "100%",
            display: "flex",
            flexDirection: "row",
            alignItems: "stretch",
            justifyContent: "center",
            gap: 1,
          }}
        >
          <Box
            sx={{
              flex: 2,
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            <Typography
              fontSize={{ xs: ".9em", sm: ".9em", md: "1em", lg: "1em" }}
              sx={{
                fontWeight: 700,
                textTransform: "capitalize",
              }}
            >
              {/* Paket Hemat Seojun Jumbo */}
              {item.name}
            </Typography>
            <Typography
              sx={{
                fontSize: ".7em",
                fontWeight: 300,
                marginTop: ".3em",
              }}
            >
              {/* 2 Potong Ayam Goreng Bumbu ( Dada Dan Sayap ), Nasi Jumeokbap Dan
              Kimchi */}
              {item.desc}
            </Typography>
            <Typography
              fontSize={{ xs: ".9em", sm: ".9em", md: "1em", lg: "1em" }}
              sx={{
                fontWeight: 700,
                marginTop: ".4em",
              }}
            >
              {/* 24000 */}
              {item.price}
            </Typography>
          </Box>
          <Box
            maxHeight={100}
            maxWidth={100}
            sx={{
              flex: 1,
              backgroundColor: "aliceblue",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              objectFit: "inherit",
              overflow: "hidden",
              borderRadius: 4,
            }}
          >
            <img
              src={`https://seojunfriedchicken.com/public/menu/${item.id}.webp`}
              alt="menu"
              width="100px"
              height="100px"
            />
          </Box>
        </Box>

        {/* Tombol untuk tambah pesanan */}
        <br />
        <Divider
          width="100%"
          height="2px"
          sx={{
            marginTop: ".4em",
            marginbottom: ".4em",
          }}
        />
        <Button
          variant="outlined"
          sx={{
            fontSize: ".65em",
            borderRadius: 8,
            marginTop: "1em",
            marginBottom: ".4em",
          }}
          onClick={() => order_input_func(item)}
        >
          tambah
        </Button>
      </Paper>
    </div>
  );
};

export default ListMenuItem;
