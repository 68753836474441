import { Box, useMediaQuery } from "@mui/material";
// import MenuElement from "./menu-element-component";
import MenuElementNew from "./menu-element-new-component";

const MenuContainer = () => {
  // Screen size definition
  const mobile_size = useMediaQuery("(max-width:600px)");
  const desktop_size = useMediaQuery("(min-width:600px)");

  return (
    <>
      {/* Mobile */}
      {mobile_size && (
        <div
          className="menu-section-container-mobile dot-background"
          id="section-menu"
        >
          <Box
            width="100%"
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
          >
            <div className="menu-title-mobile">Menu</div>
            <br />

            <MenuElementNew />
          </Box>
        </div>
      )}

      {/* Desktop */}
      {desktop_size && (
        <div
          className="menu-section-container dot-background"
          id="section-menu"
        >
          <Box
            width="100%"
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
          >
            <div className="menu-title">
              Pilih Menu Ayam Goreng Seo Jun Sesuai Seleramu
            </div>
            <br />

            <MenuElementNew />
          </Box>
        </div>
      )}
    </>
  );
};

export default MenuContainer;
