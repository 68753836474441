const NotFoundPage = () => {
  return (
    <div className="container-not-found">
      <div className="title-not-found">404</div>
      <div className="subtitle-not-found">Page Not Found</div>
      <div className="desc-not-found">
        Sorry, fried chicken you are looking for is not here.
      </div>

      <a href="/" className="button-not-found">
        Back To Home
      </a>
    </div>
  );
};

export default NotFoundPage;
