import { Box, Paper, Typography, IconButton } from "@mui/material";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import AddCircleIcon from "@mui/icons-material/AddCircle";
// import { useEffect, useState } from "react";
import DeleteIcon from "@mui/icons-material/Delete";

const ListCartItem = ({ item, qty_sub_func, qty_add_func, delete_item }) => {
  return (
    <Paper
      sx={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        padding: 1.2,
        marginBottom: ".6em",
      }}
    >
      <Box
        sx={{
          width: "100%",
          display: "flex",
          flexDirection: "row",
          alignItems: "stretch",
          justifyContent: "center",
          gap: 1,
        }}
      >
        <Box
          sx={{
            flex: 2,
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          <Typography
            fontSize={{ xs: ".9em", sm: ".9em", md: "1em", lg: "1em" }}
            sx={{
              fontWeight: 700,
              textTransform: "capitalize",
            }}
          >
            {/* Paket Hemat Seojun Jumbo */}
            {item.name}
          </Typography>

          {/* Saus */}
          {(item.sauce || item.sauce_1) && (
            <>
              {item.sauce ? (
                <Typography
                  sx={{
                    fontSize: ".7em",
                    fontWeight: 300,
                    marginTop: ".3em",
                  }}
                >
                  {`${item.sauce}`}
                </Typography>
              ) : (
                <Typography
                  sx={{
                    fontSize: ".7em",
                    fontWeight: 300,
                    marginTop: ".3em",
                  }}
                >
                  {`${item.sauce_1}, ${item.sauce_2}, ${item.sauce_3}, ${item.sauce_4}`}
                  {/* {item.sauce} */}
                </Typography>
              )}
            </>
          )}

          <Typography
            fontSize={{ xs: ".9em", sm: ".9em", md: "1em", lg: "1em" }}
            sx={{
              fontWeight: 700,
              marginTop: ".4em",
            }}
          >
            {/* 24000 */}
            {item.price}
          </Typography>

          <br />

          <Box
            sx={{
              width: "100%",
              minHeight: "2em",
              display: "flex",
              flexDirection: "row",
              gap: 1,
              alignItems: "center",
              justifyContent: "flex-start",
            }}
          >
            <Typography
              fontSize={{ xs: ".9em", sm: ".9em", md: "1em", lg: "1em" }}
              sx={{
                fontWeight: 400,
                textTransform: "capitalize",
              }}
            >
              jumlah :
            </Typography>
            <IconButton
              size="small"
              onClick={() => {
                qty_sub_func(item);
              }}
            >
              <RemoveCircleIcon color="primary" width="1em" />
            </IconButton>
            <Typography
              fontSize={{ xs: ".9em", sm: ".9em", md: "1em", lg: "1em" }}
              sx={{
                fontWeight: 700,
              }}
            >
              {item.qty}
            </Typography>
            <IconButton
              size="small"
              onClick={() => {
                qty_add_func(item);
              }}
            >
              <AddCircleIcon color="primary" width="1em" />
            </IconButton>
          </Box>
        </Box>
        <Box
          maxHeight={100}
          maxWidth={100}
          sx={{
            flex: 1,
            backgroundColor: "aliceblue",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            objectFit: "inherit",
            overflow: "hidden",
            borderRadius: 4,
          }}
        >
          <img
            src={`https://seojunfriedchicken.com/public/menu/${item.id}.webp`}
            alt="menu"
            width="100px"
            height="100px"
          />
        </Box>
      </Box>
      <Box
        width="100%"
        display="flex"
        flexDirection="row"
        justifyContent="flex-end"
        alignItems="center"
        onClick={() => {
          delete_item(item);
        }}
      >
        <Typography
          sx={{
            fontSize: ".8em",
            color: "red",
            textTransform: "capitalize",
          }}
        >
          hapus item
        </Typography>
        <IconButton size="small">
          <DeleteIcon fontSize="smaller" color="error" />
        </IconButton>
      </Box>

      {/* <Button
          variant="outlined"
          sx={{
            fontSize: ".65em",
            borderRadius: 8,
            marginTop: "1em",
            marginBottom: ".4em",
          }}
          onClick={() => order_input_func(item)}
        >
          tambah
        </Button> */}
    </Paper>
  );
};

export default ListCartItem;
