import { Box, Typography, useMediaQuery } from "@mui/material";
import Logo from "../assets/logo.png";

const LocationContainer = () => {
  const alamat = `Jl. Taman Cibeunying Utara ( Pasar Audio Cihapit )`;
  const kecamatan = `Kec. Bandung Wetan. Bandung 40114`;

  // Screen size definition
  const mobile_size = useMediaQuery("(max-width:800px)");
  const desktop_size = useMediaQuery("(min-width:800px)");

  return (
    <>
      {mobile_size && (
        <div
          className="location-section-container-mobile"
          id="section-location"
        >
          <Box
            width="100%"
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
          >
            <div className="menu-title-mobile">Kunjungi Kami</div>
            <br />
            {/* Maps */}
            <div className="mapouter-mobile">
              <div className="gmap_canvas-mobile">
                <iframe
                  title="map"
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3960.8714795405535!2d107.62252207498436!3d-6.905968293093409!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2e68e719305e7b8f%3A0xe86be60acaeebf22!2sSeo%20jun%20fried%20chicken!5e0!3m2!1sen!2sid!4v1688129847520!5m2!1sen!2sid"
                  width="350"
                  height="200"
                  allowFullScreen=""
                  loading="lazy"
                  referrerPolicy="no-referrer-when-downgrade"
                ></iframe>
              </div>
            </div>
            <Box
              display="flex"
              flexDirection="column"
              alignItems="flex-end"
              justifyContent="center"
              marginTop="1em"
            >
              <img src={Logo} alt="logo seo jun" width="20%" />
              <Typography fontWeight="Bold" color="ivory">
                Seo Jun Fried Chicken
              </Typography>

              <br />
              <Typography
                fontWeight="400"
                fontSize=".7em"
                color="ivory"
                textAlign="right"
              >
                {alamat}
                <br />
                {kecamatan}
                <br />
                Telp. 082219262613
              </Typography>
            </Box>
          </Box>
        </div>
      )}

      {/* Desktop */}
      {desktop_size && (
        <div className="location-section-container" id="section-location">
          <Box
            width="100%"
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
          >
            <div className="menu-title">Kunjungi Kami</div>
            <br />
          </Box>
          <Box display="flex" flexDirection="row" gap={3}>
            <Box flex={1}>
              <div className="mapouter">
                <div clasName="gmap_canvas">
                  <iframe
                    title="seojun_location"
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3960.8714795405535!2d107.62252207456285!3d-6.905968293093409!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2e68e719305e7b8f%3A0xe86be60acaeebf22!2sSeo%20jun%20fried%20chicken!5e0!3m2!1sen!2sid!4v1687924155910!5m2!1sen!2sid"
                    width="600"
                    height="300"
                    style={{ border: 0 }}
                    allowFullScreen=""
                    loading="lazy"
                    referrerPolicy="no-referrer-when-downgrade"
                  ></iframe>
                </div>
              </div>
            </Box>
            <Box
              flex={1}
              display="flex"
              flexDirection="column"
              alignItems="flex-end"
              justifyContent="center"
            >
              <img src={Logo} alt="logo seo jun" width="20%" />
              <Typography fontWeight="Bold" color="ivory">
                Seo Jun Fried Chicken
              </Typography>

              <br />
              <Typography fontWeight="400" color="ivory" textAlign="right">
                {alamat}
                <br />
                {kecamatan}
                <br />
                Telp. 082219262613
              </Typography>
            </Box>
          </Box>
        </div>
      )}
    </>
  );
};

export default LocationContainer;
